.cookies {
  z-index: 50;
  text-align: initial;
  line-height: initial;
  color: var(--fuse-primary-50);

  a {
    color: var(--fuse-accent-400);
    text-decoration: none;
  }

  .alert-warning {
    @apply rounded-md;
    background: var(--fuse-primary-700);
    padding: 15px;
  }

  @screen md {
    max-width: 400px;
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

  //@screen xs {
  //  h3 {
  //    margin-top: 0px;
  //  }
  //  width: 100%;
  //  font-size: 0.9em;
  //}

}
