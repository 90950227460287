/* Charts */

$chartLineColor: var(--fuse-primary-800);
$averageChartLineColor: var(--fuse-accent-500);

.consumption svg .active {
  display: block !important;
}

.consumption svg {
  border-radius: 0px;
}

svg {
  .line {
    fill: none;
    stroke: $chartLineColor;
    stroke-width: 3;
  }

  .averageLine-container .line {
    stroke: $averageChartLineColor;
  }

  .area {
    fill: transparent;
    opacity: .60;
  }

  .averageArea-container .area {
    fill: #fff;
    opacity: .50;
  }

  .node {
    fill: $chartLineColor;
  }
  .node.active {
    stroke-width: 4;
    stroke: $chartLineColor;
    fill: white;
  }

  .node-label {
    @apply hidden;

    &.active {
      @apply block;
    }

    text {
      font-size: 13px;
      fill: var(--fuse-primary-900);
      text-anchor: middle;

      @screen md {
        font-size: 8px;
      }
    }
    rect, path {
      fill: #fff;
    }
  }

  .xaxis text {
    display: none;
    font-size: 8px;
    text-transform: uppercase;
    fill: var(--fuse-primary-900);
    //@media (min-width: @screen-sm-min) {
    //  font-size: 10px;
    //}
  }

  .xaxis text.permanent {
    display: block;
  }

  .xaxis text.active {
    fill: #fff;
    display: block;
  }

  .yaxis text {
    font-size: 8px;
    fill: var(--fuse-icon);
    text-anchor: start !important;
    //@media (min-width: @screen-sm-min) {
    //  font-size: 10px;
    //}
  }

  .chart-title text {
    font-size: 8px;
    //@media (min-width: @screen-sm-min) {
    //  font-size: 12px;
    //}
  }

  .year-separator {
    text {
      fill: var(--fuse-primary-900);
      font-size: 8px;
      //@media (min-width: @screen-sm-min) {
      //  font-size: 11px;
      //}
    }
  }

  cursor: pointer;
  .bar, .node-label.active, .xaxis-label.active {
    cursor: col-resize;
  }

  .bar {
    rect {
      fill: var(--fuse-accent-300);
      opacity: 0.48;
    }
  }
}


body:not(.branding){
  svg {
    .bar {
      rect {
        fill: var(--fuse-accent-500);
      }
    }
  }
}
