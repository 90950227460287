.mat-button-toggle .mat-button-toggle-button {
  .mat-pseudo-checkbox {
    @apply hidden;
  }

  .mat-button-toggle-label-content {
    @apply justify-center;
  }
}

.mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-content {
  @apply flex-col gap-2;

  @screen md {
    @apply flex-row;
  }
}

.cdk-overlay-pane.mat-mdc-dialog-panel {
  --mat-dialog-container-max-width: 40rem;
}

.mat-mdc-menu-panel .mat-ripple-element, .mat-mdc-menu-panel .mat-mdc-menu-item.cdk-keyboard-focused, .mat-mdc-menu-panel .mat-mdc-menu-item:hover {
  background-color: rgba(var(--fuse-primary-500-rgb), .05) !important;
}

[mat-icon-button] > img{
  z-index:1;
}
