@use "@angular/material" as material;
@use "sass:map";

body {
  @apply overflow-hidden;

  .ista-footer {
    @apply flex flex-col flex-wrap p-2;

    a.mdc-button.mat-mdc-button {
      @apply text-sm font-normal text-slate-600;
    }

    @screen md {
      @apply flex-row justify-end;
    }
  }

  .ista-ui-layout {
    @apply relative flex;
    height: calc(100vh - 1px);

    & > section {
      @apply flex-auto;
      width: 100%;
      min-height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;

      .mat-toolbar.ista-ui-header {
        border-bottom: 1px solid;
        @apply sticky top-0 border-slate-200 z-10;
      }

      main {
        @apply w-full self-center items-center p-8;
        flex: 1 1 auto;
      }
    }
  }

  .ista-ui-layout-card {
    @apply relative flex;
    height: 100vh;

    & > section {
      @apply flex-auto;
      overflow-y: auto;
      min-height: 100%;
      display: flex;
      flex-direction: column;

      .mat-toolbar.ista-ui-header {
        @apply sticky top-0 bg-slate-100;
        z-index: 99;
      }

      main {
        @apply flex flex-col self-center items-center p-8;
        flex: 1 1 auto;
        max-width: 1200px;

        .mat-mdc-card.mdc-card {
          @apply bg-slate-200;
          @include material.elevation(8);

          .mat-mdc-card-header {
            background-color: #fff;

            h2 {
              @apply m-0 font-medium text-sm uppercase;

              color: var(--fuse-primary-900);
            }
          }

          .mat-mdc-card-content {
            @apply text-slate-600;
            padding: 25px 2rem;
          }

          .mat-mdc-card-header,
          .mat-mdc-card-actions {
            padding: 15px 2rem;
          }
        }
      }
    }
  }

  .fuse-drawer-overlay {
    background-color: #0009;
  }

  fuse-drawer.ista-vertical-navigation {
    background-color: #fff;
    box-shadow: none;
    border-right: 1px solid;
    @apply border-slate-200;

    .ista-sidenav {
      @apply flex flex-col relative;
      width: var(--fuse-drawer-width);

      .ista-vertical-navigation-item {
        & > a {
          @apply w-full justify-start;

          mat-icon {
            margin-right: 16px !important;
          }

          &.active {
            color: rgba(var(--fuse-primary-rgb), var(--tw-text-opacity)) !important;
            background-color: rgba(var(--fuse-primary-rgb), .05) !important;

            .mat-icon {
              color: rgba(var(--fuse-primary-rgb), var(--tw-text-opacity)) !important;
            }
          }

          &:hover {
            background-color: rgba(var(--fuse-primary-rgb), .1) !important;
          }
        }
      }
    }
  }

  .ipmp-language-selector-menu {
    .mdc-list {
      @apply pt-0 pb-0;

      .mat-mdc-menu-item {
        &.selected {
          background-color: rgba(var(--fuse-primary-rgb), .05);

          span {
            color: var(--fuse-primary-700);
          }
        }
      }
    }
  }
}

body:not(.branding){

  fuse-drawer.ista-vertical-navigation {
    .ista-sidenav {
      .ista-vertical-navigation-item {
        & > a {
          &.active {
            @apply bg-base-50 #{!important};
          }
          &:hover {
            @apply bg-base-100 #{!important};
          }
        }
      }
    }
  }

}