a.mdc-button, button.mdc-button {
  &.wrap-long {
    @apply min-w-0 #{!important};

    .mdc-button__label {
      @apply overflow-hidden overflow-ellipsis;
      flex-basis: fit-content;
    }
  }
}
