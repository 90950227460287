.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    flex: none;
  }
}

// NOTE: this unlocks the mat-form-field height when the error message needs multiple lines
.theme-default.light .mat-primary {
  .mat-mdc-form-field-subscript-wrapper {
    @apply flex flex-1;

    .mat-mdc-form-field-error-wrapper {
      @apply relative;
    }
  }
}

.mat-mdc-slide-toggle {
  .mat-internal-form-field {
    @apply gap-2;
  }
}
