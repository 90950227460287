// NOTE: this file simplifies the color usages by binding them to the --fuse variables.
// This also makes the client branding much easier.

.branding{

  &.theme-default .light,
  &.theme-default.light {

    // angular material overrides
    .mat-mdc-button:not(:disabled),
    .mat-mdc-outlined-button:not(:disabled) {
      &.mat-primary {
        --mdc-text-button-label-text-color: var(--fuse-primary-500) !important;
        --mdc-outlined-button-label-text-color: var(--fuse-primary-500) !important;
      }

      &.mat-accent {
        --mdc-text-button-label-text-color: var(--fuse-accent-500) !important;
        --mdc-outlined-button-label-text-color: var(--fuse-accent-500) !important;
      }
    }

    .mat-mdc-raised-button:not(:disabled) {
      &.mat-primary {
        --mdc-protected-button-label-text-color: var(--fuse-primary-50) !important;
        --mdc-text-button-label-text-color: var(--fuse-primary-50) !important;
        --mdc-protected-button-container-color: var(--fuse-primary-500) !important;
      }

      &.mat-accent {
        --mdc-protected-button-label-text-color: var(--fuse-accent-50) !important;
        --mdc-text-button-label-text-color: var(--fuse-accent-50) !important;
        --mdc-protected-button-container-color: var(--fuse-accent-500) !important;
      }
    }

    .mat-button-toggle-group {
      .mat-button-toggle {
        &.mat-button-toggle-checked {
          background-color: var(--fuse-primary-50) !important;
        }
      }
    }

    .mat-icon {
      &.mat-primary {
        --mat-icon-color: var(--fuse-primary-500) !important;
      }

      &.mat-accent {
        --mat-icon-color: var(--fuse-accent-500) !important;
      }
    }

    .mat-mdc-unelevated-button:not(:disabled) {
      background-color: var(--fuse-accent-600) !important;
    }

    .mat-primary {

      .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
      .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
        border-color: var(--fuse-primary-600) !important;
      }

      .mat-mdc-tab .mdc-tab__text-label,
      .mat-mdc-tab-link .mdc-tab__text-label {
        color: var(--fuse-primary-600) !important;
      }
    }

    .mat-accent {

      .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
      .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
        border-color: var(--fuse-accent-500) !important;
      }

      .mat-mdc-tab .mdc-tab__text-label,
      .mat-mdc-tab-link .mdc-tab__text-label {
        color: var(--fuse-accent-500) !important;
      }
    }

    .mat-mdc-select-arrow svg {
      color: var(--fuse-mat-icon) !important;
    }

    .mat-mdc-progress-bar {
      &.mat-primary .mdc-linear-progress__bar-inner {
        border-color: var(--fuse-primary-500);
      }

      &.mat-accent .mdc-linear-progress__bar-inner {
        border-color: var(--fuse-accent-500);
      }
    }

    // ista-ui overrides

    .ista-table-shading .mat-mdc-table thead th,
    .ista-table-shading.mat-mdc-table thead th {
      @apply bg-primary-50/35 #{!important};
    }
  }

  .branding-room-selected-fix{
    @apply bg-primary-50 text-primary-500;
  }
  .branding-room-hover-fix:hover{
    @apply bg-primary-50;
  }

 
}

body:not(.branding){

   .mat-mdc-raised-button:not(:disabled) {
      &.mat-primary {
        --mdc-protected-button-label-text-color: var(--fuse-primary-50) !important;
        --mdc-text-button-label-text-color: var(--fuse-primary-50) !important;
        --mdc-protected-button-container-color: var(--fuse-primary-500) !important;
      }
   }

  ic-breadcrumb div:first-child {
    @apply bg-base-50 text-base-600 #{!important};
  }

  .branding-room-selected-fix{
    @apply bg-primary-100 text-primary-500;
  }

  .branding-room-hover-fix:hover{
    @apply bg-primary-100;
  }
  
}

