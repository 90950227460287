/* stylelint-disable */
@use 'material-symbols';
@use '@ista-ui/styles/preflight.css';
@use '@ista-ui/angular/index';

@tailwind utilities;

@import 'preflight';
@import 'color-overrides';
@import 'material-overrides';
@import 'layout';
@import 'forms';
@import 'buttons';
@import 'tables';
@import 'notifications';
@import 'charts';
@import 'cookies';

.svg-container{
    height: 100%;
    width: 100%;
}

.limitChartWidth{
    max-width:1200px;
}
