.mat-mdc-paginator .mat-mdc-paginator-container {
  .mat-mdc-paginator-page-size {
    @apply hidden;
  }

  @screen md {
    .mat-mdc-paginator-page-size {
      @apply flex;
    }

    @apply justify-end;
  }
}
@media (min-width: 768px) {
  .mat-mdc-form-field-infix mat-label.mat-sort-header {
      height: inherit !important;
  }
}
