.toast-container {
  fuse-alert {
    .fuse-alert-container {
      .fuse-alert-icon {
        .mat-icon {
          font-size: 1.5rem !important;
          line-height: 1.5rem !important;
        }
      }
    }
  }
}

main > {
  fuse-alert {
    display: flex !important;
  }
}